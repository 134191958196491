body:has(.single-page) {
  @apply bg-[linear-gradient(335deg,_#FFF7E0_18.72%,_rgba(255,_247,_224,_0.00)_78.19%)] md:bg-[linear-gradient(to_right,_white,_white)];
}

.landing-page {
  @apply bg-[linear-gradient(335deg,_#fdfaf1_18.72%,_rgba(255,_247,_224,_0.00)_78.19%)];
}

/* Squash and Release Animation */
@keyframes squashStretchText {
  0% {
    transform: scaleX(1) scaleY(1); /* Normal scale */
  }

  10% {
    transform: scaleX(0.8) scaleY(1.2); /* Slight horizontal squash and vertical stretch */
  }

  50% {
    transform: scaleX(1.2) scaleY(0.8); /* Stronger horizontal stretch and vertical squish */
  }

  100% {
    transform: scaleX(1) scaleY(1); /* Back to normal scale */
  }
}

/* Fade In & Push from Right */
@keyframes fadeInPushRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  30% {
    opacity: 1;
    transform: translateX(-20%); /* Move deeper into the text */
  }
  50% {
    transform: translateX(0); /* Snap back to the original position */
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInPushLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  30% {
    opacity: 1;
    transform: translateX(20%); /* Move deeper into the text */
  }
  50% {
    transform: translateX(0); /* Snap back to the original position */
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (min-width: 1024px) {
  /* Fade In & Push from Left */
  @keyframes fadeInPushLeft {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    30% {
      opacity: 1;
      transform: translateX(0); /* Move deeper into the text */
    }
    50% {
      transform: translateX(0); /* Snap back to the original position */
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes fadeInPushLeft {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    30% {
      opacity: 1;
      transform: translateX(0); /* Move deeper into the text */
    }
    50% {
      transform: translateX(0); /* Snap back to the original position */
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

@media (min-width: 1536px) {
  /* Fade In & Push from Left */
  @keyframes fadeInPushLeft {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    30% {
      opacity: 1;
      transform: translateX(20%); /* Move deeper into the text */
    }
    50% {
      transform: translateX(0); /* Snap back to the original position */
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes fadeInPushLeft {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    30% {
      opacity: 1;
      transform: translateX(20%); /* Move deeper into the text */
    }
    50% {
      transform: translateX(0); /* Snap back to the original position */
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes reduce-height {
    0% {
      @apply h-28;
    }
    100% {
      @apply h-0; /* Reduced height */
    }
  }
}

/* 640  */

@media (min-width: 640px) {
  @keyframes reduce-height {
    0% {
      @apply h-16;
    }
    100% {
      @apply h-0; /* Reduced height */
    }
  }
}

.push-from-right {
  display: inline-block;
  animation: fadeInPushRight 2s ease-out forwards; /* Slower duration */
}

.push-from-left {
  display: inline-block;
  animation: fadeInPushLeft 2s ease-out forwards; /* Slower duration */
}

@keyframes reduce-height {
  0% {
    @apply h-12;
  }
  100% {
    @apply h-0; /* Reduced height */
  }
}

.reduce-nav-bar-height {
  animation: reduce-height 0.5s ease-in-out forwards; /* Apply the animation */

  @apply h-12;
  overflow: hidden; /* To hide content while reducing height */
}

/* SVG Style */
.slide-in-svg-right {
  opacity: 0; /* Start invisible */
  transform: translateX(100%); /* Start off-screen to the right */
  animation: springInRight 1s cubic-bezier(0.4, 0, 0.2, 1) forwards; /* Apply springInRight animation with cubic-bezier easing */
}

/* Keyframes for the spring-in effect */
@keyframes springInRight {
  0% {
    opacity: 0;
    transform: translateX(100%); /* Start off-screen to the right */
  }
  40% {
    opacity: 1;
    transform: translateX(-10%); /* Overshoot to the left a bit */
  }
  60% {
    transform: translateX(5%); /* Spring back to the right a bit */
  }
  100% {
    opacity: 1;
    transform: translateX(0); /* Final position (on-screen) */
  }
}
/* Staggering delay for each element */
.slide-in-svg-right:nth-child(1) {
  animation-delay: 0.2s;
}

.slide-in-svg-right:nth-child(2) {
  animation-delay: 0.4s;
}

.slide-in-svg-right:nth-child(3) {
  animation-delay: 0.6s;
}

.slide-in-svg-right:nth-child(4) {
  animation-delay: 0.8s;
}

.slide-in-svg-right:nth-child(5) {
  animation-delay: 1s;
}

.slide-in-svg-right:nth-child(6) {
  animation-delay: 1.2s;
}

.slide-in-svg-left {
  opacity: 0; /* Start invisible */
  transform: translateX(-100%); /* Start off-screen to the left */
  animation: springInFromLeft 1s cubic-bezier(0.4, 0, 0.2, 1) forwards; /* Apply springInFromLeft animation */
}

/* Keyframes for the spring-in effect from the left */
@keyframes springInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%); /* Start off-screen to the left */
  }
  40% {
    opacity: 1;
    transform: translateX(10%); /* Overshoot slightly to the right */
  }
  60% {
    transform: translateX(-5%); /* Spring back a little to the left */
  }
  100% {
    opacity: 1;
    transform: translateX(0); /* Final position (on-screen) */
  }
}

.slide-in-svg-left:nth-child(1) {
  animation-delay: 1s;
}

.slide-in-svg-left:nth-child(2) {
  animation-delay: 0.8s;
}

.slide-in-svg-left:nth-child(3) {
  animation-delay: 0.6s;
}

.slide-in-svg-left:nth-child(4) {
  animation-delay: 0.4s;
}

.slide-in-svg-left:nth-child(5) {
  animation-delay: 0.2s;
}

.slide-in-svg-left:nth-child(6) {
  animation-delay: 0s;
}

/* blog scroll */

.tableOfContent:target {
  scroll-margin-top: 150px;
}
